<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import draggable from 'vuedraggable'
import auth from "@/auth/authService";

export default {
  name: "Details",
  components: {draggable, 'tinymceEditor': Editor, 'vueDropzone': vue2Dropzone},
  mixins: [tinymce],
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  data: function async () {
    let self = this;
    return {
      gallery: {
        id: 0,
        name: '',
        description: '',
        active: true,
        pictures: []
      },
      dropzoneOptions: {
        url: 'see dropzoneMounted()',
        createImageThumbnails: false,
        resizeWidth: 1024,
        paramName: 'new_picture',
        acceptedFiles: '.jpg,.jpeg,.png',
        dictDefaultMessage: '<h4><i class="fa fa-plus"></i> Bilder hier einfügen</h4>',
        dictInvalidFileType: 'Dateien dieses Typs werden nicht unterstützt',
        success: function(file, response) {
          this.removeFile(file);
          self.gallery.pictures.push(response.data);
        }
      }
    }
  },
  created: function(){
    let self = this;
    if (self.$route.params.id > 0) {
      Http.request('GET', '/galleries/' + self.$route.params.id).then(function (response) {
        self.gallery = response.data;
      });
    }
  },
  methods: {
    save () {
      let self = this;
      Http.request('POST', '/galleries/' + self.gallery.id, self.gallery).then(function (response) {
        if(self.gallery.id === 0){
          self.$set(self, 'gallery', response.data);
          self.$set(self.gallery, 'pictures', []);
          self.$router.replace('/website/galleries/' + response.data.id);
        }
        else {
          self.$router.push('/website/galleries');
        }
      });
    },
    deletePicture(key){
      let self = this;
      self.$bvModal.msgBoxConfirm("Möchstest du dieses Bild wirklich löschen?", {
          headerBgVariant: 'primary',
          title: 'Bild löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer) {
            self.gallery.pictures.splice(key, 1)
          }
        });
    },
    dropzoneMounted () {
      let self = this;
      auth.getIdToken().then((token) => {
        self.$refs.galleryDropzone.setOption('url', process.env.VUE_APP_BACKEND_URL + '/galleries/' + self.gallery.id + '/upload');
        self.$refs.galleryDropzone.setOption('headers', {
          'Authorization': 'Bearer ' + token,
          'Cache-Control': null,
          'X-Requested-With': null
        });
      });
    }
  }
}
</script>

<style scoped>
  .picture-sort .card {
    min-height: 93%;
    border-radius: 0;
  }
  .picture-sort .card-img-top {
    max-height: 120px;
  }
  .picture-sort .card .card-body {
    padding: 0.75rem;
  }
</style>
